import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { seo, intro, main } from '../content/uslugi'
import { useLangContext } from '../context/lang.context'
import { useFormContext } from '../context/form.context'
import Intro from '../components/Intro'
import Button from '../components/Button'
import { sLinkTiles } from '../style'
import LinkTile from '../components/LinkTile'
import Main from '../components/Main'

const subtitle = {
  pl: 'Usługi',
  en: 'Services',
  ua: 'Послуги',
}

const Uslugi = () => {
  const { lang } = useLangContext()
  const { setFormOpen, setFormType } = useFormContext()

  const { image, services, transportSea, transportAir, transportLand } =
    useStaticQuery(graphql`
      {
        image: file(absolutePath: { regex: "/images/intros/uslugi.jpg/" }) {
          publicURL
          childImageSharp {
            gatsbyImageData(
              layout: FIXED
              quality: 100
              placeholder: BLURRED
              formats: [JPG]
            )
          }
        }
        services: allContentfulService(sort: { fields: order }) {
          nodes {
            title
            type {
              name
            }
            lang {
              slug
            }
            slug
            image {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 100
                placeholder: BLURRED
                formats: [JPG]
              )
            }
          }
        }
        transportSea: file(
          absolutePath: { regex: "/images/intros/transport-morski.jpg/" }
        ) {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              quality: 100
              placeholder: BLURRED
              formats: [JPG]
            )
          }
        }
        transportAir: file(
          absolutePath: { regex: "/images/intros/transport-lotniczy.jpg/" }
        ) {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              quality: 100
              placeholder: BLURRED
              formats: [JPG]
            )
          }
        }
        transportLand: file(
          absolutePath: { regex: "/images/intros/transport-ladowy.jpg/" }
        ) {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              quality: 100
              placeholder: BLURRED
              formats: [JPG]
            )
          }
        }
      }
    `)

  const button = {
    text: {
      pl: 'Przeglądaj usługi',
      en: 'Explore services',
      ua: 'ПЕРЕГЛЯНУТИ ПОСЛУГИ',
    },

    action: 'SCROLL',
  }

  const buttonSecond = {
    text: {
      pl: 'Zapytaj o ofertę',
      en: 'Ask for an offer',
      ua: 'ЗАПИТАЙТЕ ПРОПОЗИЦІЮ',
    },
    action: () => {
      setFormType('MESSAGE')
      setFormOpen(true)
    },
  }

  const buttonBack = {
    text: {
      pl: 'Sprawdź rodzaje transportu',
      en: 'Check out transport types',
      ua: 'Перевірте види транспорту',
    },
    link: '/transport/',
  }

  const links = services.nodes
    .filter((node) => node.lang.slug === lang)
    .filter((node) => node.type.name === 'service')
    .map(({ slug, title, image }) => ({
      slug: `/services/${slug}/`,
      title,
      image,
      subtitle,
    }))
    .concat([
      {
        slug: `/transport/sea/`,
        title: {
          pl: 'Transport Morski',
          en: 'Sea Transport',
          ua: 'Морський транспорт',
        },
        image: transportSea.childImageSharp,
        subtitle,
      },
      {
        slug: `/transport/air/`,
        title: {
          pl: 'Transport Lotniczy',
          en: 'Air Transport',
          ua: 'Повітряний транспорт',
        },
        image: transportAir.childImageSharp,
        subtitle,
      },
      {
        slug: `/transport/land/`,
        title: {
          pl: 'Transport Lądowy',
          en: 'Land Transport',
          ua: 'Наземний транспорт',
        },
        image: transportLand.childImageSharp,
        subtitle,
      },
    ])

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        url={seo.url}
        keywords={seo.keywords}
      />
      <Intro
        data={{ ...intro, button, buttonSecond }}
        image={image.childImageSharp}
        position='top'
      />
      <Main h={1} title={main.title[lang]} body={main.body[lang]}>
        <div css={sLinkTiles}>
          {links.map((item, id) => (
            <LinkTile key={id} data={item} />
          ))}
        </div>
        <Button link={buttonBack.link}>{buttonBack.text[lang]}</Button>
      </Main>
    </Layout>
  )
}

export default Uslugi
